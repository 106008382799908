// App.js
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { NavLink, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { Strapi } from './functions/Strapi';
import ArticleCorrectionPage from './pages/ArticleCorrectionPage/ArticleCorrectionPage';
import ArticleErrorPage from './pages/ArticleErrorPage/ArticleErrorPage';
import ArticlePage from './pages/ArticlePage';
import BaustellenPage from './pages/BaustellenPage/BaustellenPage';
import EventsPage from './pages/EventsPage';
import HomePage from './pages/HomePage/HomePage';
import TokensPage from './pages/TokensPage';

const Events = () => <h2>Events</h2>;
const Articles = () => <h2>Articles</h2>;
const NotFound = () => <h2>404 - Page not found</h2>;




const App = () => {


  const [Loading, setLoading] = useState(true)
  const [Count, setCount] = useState(0)

  const fetchData = async () => {
    try {
      const response = await axios({
        url: Strapi.API_ADDRESS + "/mails?sort=headerDate:DESC&filters[type][$eq]=PRESS_RELEASE&filters[article][id][$null]=true&filters[progressErrorTitle][$null]=false",
        headers: Strapi.DEFAULT_HEADERS,
        method: "GET"
      });
      setCount(response?.data?.meta?.pagination?.total)
      setLoading(false)
    } catch (error) {
      console.error('Fehler beim Abrufen der Daten:', error);
    }
  };

  useEffect(() => {
    fetchData();
    const interval = setInterval(() => {
      fetchData();
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('expiryDate');
    alert("Schade das du gehst Bruder.")
    window.location.reload();
  };

  return (
    <Router>
      <div className=" mx-auto p-4 App">

        <div className=''>
          <div className='d-flex'>
            <img src="./assets/images/laraleyla_logo.png" alt="Logo" className="logo" />
            <h1 className="text-2xl font-bold">Monitor</h1>
          </div>
          <hr />
        </div>

        <div className="row">

          <div className="col-2">
            <ul className="list-group">

              <div className='mb-3'>
                <small>Allgemein:</small>
                <li className="list-group-item">
                  <NavLink exact to="/" activeClassName="active" className="nav-link">Home</NavLink>
                </li>
              </div>

              <div className='mb-3'>
                <small>Events:</small>
                <li className="list-group-item">
                  <NavLink to="/events" activeClassName="active" className="nav-link">Fertige Events</NavLink>
                </li>
              </div>

              <div className='mb-3'>
                <small>Artikel:</small>
                <li className="list-group-item">
                  <NavLink to="/articles" activeClassName="active" className="nav-link">Warteschlange</NavLink>
                </li>
                <li className="list-group-item">
                  <NavLink to="/articles/corrections" activeClassName="active" className="nav-link">Korrekturen</NavLink>
                </li>
                <li className="list-group-item">
                  <NavLink to="/articles/errors" activeClassName="active" className="nav-link">Fehler: {Count}</NavLink>
                </li>
              </div>


              <div className='mb-3'>
                <small>Erweitert:</small>
                <li className="list-group-item">
                  <NavLink to="/baustellen" activeClassName="active" className="nav-link">Baustellen</NavLink>
                </li>
                <li className="list-group-item">
                  <NavLink to="/tokens" activeClassName="active" className="nav-link">Tokens</NavLink>
                </li>
              </div>

              <small onClick={handleLogout}>Logout</small>
            </ul>
          </div>

          <div className="col-10">
            <Routes>
              <Route path="/" element={<HomePage />} />  {/* Using element prop for content */}
              <Route path="/events" element={<EventsPage />} />
              <Route path="/tokens" element={<TokensPage />} />
              <Route path="/baustellen" element={<BaustellenPage />} />
              <Route path="/articles" element={<ArticlePage />} />
              <Route path="/articles/errors" element={<ArticleErrorPage />} />
              <Route path="/articles/corrections" element={<ArticleCorrectionPage />} />
              <Route path="*" element={<h1>404</h1>} /> {/* Catch-all route for not found */}
            </Routes>
          </div>
        </div>
      </div>
    </Router>
  );
};

export default App;
