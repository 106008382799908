import React, { useEffect, useState } from 'react';

import axios from 'axios';
import { Strapi } from '../../functions/Strapi';


const ArticleCorrectionPage = () => {

    const [Data, setData] = useState(null);
    const [Loading, setLoading] = useState(true);
    const [Error, setError] = useState(false)

    let endpoint = Strapi.API_ADDRESS + "/mails?type=CORRECTION&sort=headerDate:DESC"

    const fetchData = async () => {
        try {
            const response = await axios({
                url: endpoint,
                headers: Strapi.DEFAULT_HEADERS,
                method: "GET"
            });
            setData(response.data?.data?.attributes);
            setLoading(false);
        } catch (error) {
            setError(error);
            setLoading(false);
            console.error('Fehler beim Abrufen der Daten:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
        const interval = setInterval(() => {
            fetchData();
        }, 5 * 1000);

        return () => clearInterval(interval);
    }, []);

    if (Error) return <p>Ein Fehler ist aufgetreten. {endpoint}</p>;
    if (Loading || !Data) return <p>Lädt...</p>;

    return (
        <div className="container mx-auto p-4">
            <div className='row'>
                <div className='col-12 col-lg-12'>
                    {JSON.stringify(Data)}
                </div>
            </div>
        </div>
    );
};

export default ArticleCorrectionPage;
