import React from "react"
import "./HomePage.scss"

function HomePage({ }) {

    // const [Loading, setLoading] = useState(true)
    // const [Count, setCount] = useState(0)

    // const fetchData = async () => {
    //   try {
    //     const response = await axios({
    //       url: Strapi.API_ADDRESS + "/mails?sort=headerDate:DESC&filters[type][$eq]=PRESS_RELEASE&filters[article][id][$null]=true&filters[progressErrorTitle][$null]=false",
    //       headers: Strapi.DEFAULT_HEADERS,
    //       method: "GET"
    //     });
    //     setCount(response?.data?.meta?.pagination?.total)
    //     setLoading(false)
    //   } catch (error) {
    //     console.error('Fehler beim Abrufen der Daten:', error);
    //   }
    // };

    // useEffect(() => {
    //   fetchData();
    //   const interval = setInterval(() => {
    //     fetchData();
    //   }, 10000);

    //   return () => clearInterval(interval);
    // }, []);



    return (
        <div className="HomePage">
            <p><b>Status:</b> KA</p>
        </div>
    )
}
export default HomePage