export function formatTimeAgo(timestamp) {
    const currentTime = new Date();
    const articleTime = new Date(timestamp);
    const timeDifference = currentTime - articleTime;
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
        return `vor ${days}T`;
    } else if (hours > 0) {
        return `vor ${hours}h ${minutes % 60}m`;
    } else if (minutes > 0) {
        return `vor ${minutes}m`;
    } else {
        return `vor ${seconds}s`;
    }
}