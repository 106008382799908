import axios from "axios";
import React, { useEffect, useState } from "react";
import { Strapi } from "../../functions/Strapi";
import { updateBaustellen } from "../../utils/sylphen/UpdateBaustellen";
import "./BaustellenPage.scss";

function BaustellenPage() {
    const [Data, setData] = useState(null);
    const [Loading, setLoading] = useState(true);
    const [PageSize, setPageSize] = useState(100); // Standardwert ist 100
    const [shouldFetch, setShouldFetch] = useState(false); // Steuert, wann die Daten neu geladen werden
    const [Error, setError] = useState(false)
    const fetchData = async () => {
        setError(false);
        console.log('fetchData');
        try {
            const response = await axios({
                url: `${Strapi.API_ADDRESS}/articles?filters[detourJson][$ne]=null&sort=updatedAt:DESC&pagination[pageSize]=` + PageSize,
                headers: Strapi.DEFAULT_HEADERS,
                method: "GET"
            });

            let data = updateBaustellen(response.data?.data)

            setData(data);
            setLoading(false);
            setShouldFetch(false); // Setze shouldFetch zurück, nachdem die Daten erfolgreich geladen wurden
        } catch (error) {
            setError(true);
            console.error('Fehler beim Abrufen der Daten:', error);
        }
    };

    useEffect(() => {
        fetchData();
        const interval = setInterval(() => {
            fetchData();
        }, 25 * 1000);

        return () => clearInterval(interval);
    }, []); // Initiales Laden beim ersten Rendern

    useEffect(() => {
        if (shouldFetch) {
            fetchData();
        }
    }, [shouldFetch]); // Manuelles Nachladen, wenn shouldFetch true ist

    if (Error) return <p>Ein Fehler ist aufgetreten.</p>;
    if (Loading || !Data) return <p>Lädt...</p>;
    if (Data.length === 0) return <p>Keine Daten vorhanden.</p>

    // Alle detourJson-Daten in ein Array sammeln
    const allDetourJsons = Data.map(i => i.attributes.detourJson);

    // Handler zum Ändern der PageSize
    const handlePageSizeChange = (e) => {
        const value = parseInt(e.target.value, 10);
        if (value >= 1 && value <= 200) {
            setPageSize(value);
        }
    };

    // Handler zum manuellen Aktualisieren der Daten
    const handleRefresh = () => {
        setShouldFetch(true);
    };

    return (
        <div className="BaustellenPage">
            <h1 className="mb-4">Baustellen ({Data.length})</h1>
            <p>Aktuell werden maximal {PageSize} Baustellen angezeigt.</p>


            <div className="row">
                <div className="col-8">
                    {Data.map((i, k) => <BaustellenItem {...i.attributes} key={k} />)}
                </div>
                <div className="col-4">
                    <h2>JSON-Array</h2>
                    <small>Maximal {PageSize}</small>
                    <code>{JSON.stringify(allDetourJsons)}</code>

                </div>
            </div>
        </div>
    );
}

export default BaustellenPage;



function BaustellenItem({ title, publicTimestamp, slug, detourJson }) {

    const [IsOpen, setIsOpen] = useState(true);

    return (
        <div className="BaustellenItem w-100 col-12 mb-5 mt-5">
            <div className="d-flex flex-row align-items-center justify-content-between">
                <a href={"https://herold.news/a/" + slug} target="_blank" rel="noopener noreferrer">
                    <h2>Artikel: {title}</h2>
                    <span>publicTimestamp: {publicTimestamp}</span>
                </a>
            </div>

            <div>
                <code>{JSON.stringify(detourJson)}</code>
            </div>
        </div>
    );
}
