import React, { useEffect, useState } from "react";
import { TimeAgo } from "../components/TimeAgo";
import "./CurrentProcess.scss";
// ...

function CurrentProcess({ data, hoursThreshold = 1 }) {
    const [showWarning, setShowWarning] = useState(false);
    let { title, startTimestamp, mail } = data || {}
    let { attributes, id } = mail?.data || {}
    let { subject, sender, mailID, text, headerDate } = attributes || {}

    const [IsOpen, setIsOpen] = useState(false)


    useEffect(() => {
        // Überprüfe, ob Daten vorhanden und startTimestamp definiert ist
        if (data && data.startTimestamp) {
            const currentTime = new Date();
            const diff = currentTime - new Date(data.startTimestamp);

            // Überprüfe, ob die Bearbeitungszeit länger als h Stunden ist
            setShowWarning(diff > hoursThreshold * 60 * 60 * 1000);
        }
    }, [data, hoursThreshold]);

    const options = {
        weekday: 'short',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    };

    if (showWarning) return (
        <div className="CurrentProcess p-3">
            <h1>Keine Bearbeitung</h1>
            <small>Aktuell wird keine Email bearbeitet.</small>
        </div>
    )

    function toggleOpen() {
        setIsOpen(!IsOpen)
    }

    return (
        <div className="CurrentProcess p-3" onClick={toggleOpen}>


            {IsOpen ?

                <div>
                    {title ?
                        <div>
                            <p>{subject} - Betreff</p>
                            <span>{title}</span>
                            <hr></hr>

                            {sender && (
                                <div>
                                    <span className="">Absender:</span>
                                    <p className="mb-0">{sender?.name}</p>
                                    <small>
                                        <a href={"mailto:" + sender.address} target="_blank" className="mail">
                                            <span className="">{sender?.address}</span>
                                        </a>
                                    </small>
                                    <hr></hr>
                                </div>
                            )}

                            <div>
                                <span className="">Header Date:</span>
                                <p className="mb-0"><TimeAgo>{headerDate}</TimeAgo></p>
                                <small>{new Date(headerDate).toLocaleTimeString("de-DE", options)}</small>
                                <hr></hr>
                            </div>

                            <span className="date">Wird bearbeitet seit:</span>
                            <TimeAgo>{startTimestamp}</TimeAgo>

                            <small>Mail ID: {id}</small>
                        </div>
                        :
                        <h3>Kein Title in Strapi</h3>
                    }
                </div>
                :
                <div>
                    <p>{subject}</p>
                </div>

            }
        </div >
    );
}

export default CurrentProcess;
