// EmailList.js
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { TimeAgo } from '../components/TimeAgo';
import { Strapi } from '../functions/Strapi';
import { formatTimeAgo } from '../functions/formatTimeAgo';
import "./FinishList.scss";

const FinishList = ({ }) => {

    const [Article, setArticle] = useState([])
    const [Loading, setLoading] = useState(true)
    const [Count, setCount] = useState(0)

    const fetchData = async () => {
        try {
            const response = await axios({
                url: Strapi.API_ADDRESS + "/articles?sort=updatedAt:DESC&populate[0]=author&populate[1]=audio&populate[2]=poll&populate[3]=titleImage&filters[ownArticle][$eq]=true",
                headers: Strapi.DEFAULT_HEADERS,
                method: "GET"
            });
            setArticle(response.data?.data);
            setCount(response.data?.meta?.pagination?.total);
            setLoading(false)
        } catch (error) {
            console.error('Fehler beim Abrufen der Daten:', error);
        }
    };

    useEffect(() => {
        fetchData();
        const interval = setInterval(() => {
            fetchData();
        }, 10000);

        return () => clearInterval(interval);
    }, []);


    if (Loading) return <p>Lädt...</p>
    return (
        <div className='FinishList p-3'>
            <h2 className="text-lg font-semibold mb-2">Fertige Artikel ({Count})</h2>
            {Article.map((i, k) => <ArticleItem {...i} key={k} />)}
        </div>
    );
};

export default FinishList;

function ArticleItem({ id, attributes }) {
    let { title, slug, publicTimestamp, publishedAt, createdAt, author = {}, titleImage, titleImageObject } = attributes
    const [timeAgo, setTimeAgo] = useState(formatTimeAgo(publishedAt));
    const [Clicked, setClicked] = useState(false)

    console.log(titleImageObject)

    let imageSmallUrl = titleImageObject?.data?.attributes?.formats?.thumbnail?.url || false

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeAgo(formatTimeAgo(publishedAt));
        }, 1000);

        return () => clearInterval(interval);
    }, [publishedAt]);

    let authorSlug = author?.data?.attributes?.slug
    if (!slug) return <p>Kein SLUG!!! IID: {id}</p>
    if (!authorSlug) return <p>Kein authorSlug!!! IID: {id}</p>
    let url = "https://mittelhessen.app/p/" + authorSlug + "/" + slug


    function handleClick() {
        setClicked(true)
    }

    return <div className={'item ' + (Clicked && "clicked")}>
        <div className='d-flex flex-row'>

            {imageSmallUrl ? <img style={{ width: 64, height: 64, }} src={Strapi.MAIN_ADDRESS + imageSmallUrl} /> : <div style={{ width: 64, height: 64, background: 'red' }}></div>}
            <a href={url} target='_blank' onClick={handleClick} className='d-flex flex-row'>
                <div>
                    <span className='title'>{title}</span>
                    <TimeAgo title="Internes Public Date">{publishedAt}</TimeAgo>
                    <HighlightedItems data={attributes} />
                </div>
            </a>
        </div>
    </div>
}

function isValidValue(value) {
    if (value === null || value === false || value === "false") return false;
    if (typeof value === 'object') {
        return Object.values(value).some(v => v !== null);
    }
    return true;
}

function HighlightedItems({ data = {} }) {
    // Filter highlightedKeys to include only those keys that exist in the data and have valid values
    const filteredHighlightedKeys = highlightedKeys.filter(item => item.key in data && isValidValue(data[item.key]));

    return (
        <div className='highlightedItems d-flex flex-row'>
            {filteredHighlightedKeys.map((item, index) => (
                <HighlightedItem {...item} key={index} />
            ))}
        </div>
    );
}


function HighlightedItem({ title, key, icon, emoji }) {
    return <div title={"Der Beitrag erhält: " + title} className='highlightedItem'>
        <span className='icon'>{emoji}</span>
    </div>
}

const highlightedKeys = [
    {
        title: "Baustelle",
        key: "detourJson",
        icon: "road",
        emoji: "🚧",
    },
    {
        title: "Einfache Sprache",
        key: "textEasySpeech",
        icon: "text",
        emoji: "🦽",
    },
    {
        title: "Audio-Version",
        key: "audio",
        icon: "audio",
        emoji: "🔊",
    },
    {
        title: "Video-Version",
        key: "youtubeID",
        icon: "video",
        emoji: "📹",
    },
    {
        title: "Instagram",
        key: "instagramText",
        icon: "instagram",
        emoji: "📸",
    },
    {
        title: "Umfrage",
        key: "poll",
        icon: "survey",
        emoji: "📊",
    },
    {
        title: "Termin",
        key: "ics",
        icon: "calendar",
        emoji: "📅",
    }
]