import React, { useEffect, useState } from 'react';

const TokensPage = () => {
    const [apiKey, setApiKey] = useState('');

    // Abrufen des gespeicherten API Keys beim Laden der Komponente
    useEffect(() => {
        const storedApiKey = localStorage.getItem('strapiApiKey');
        if (storedApiKey) {
            setApiKey(storedApiKey);
        }
    }, []);

    // Speichern des API Keys im Local Storage
    const handleSave = () => {
        localStorage.setItem('strapiApiKey', apiKey);
        alert('API Key gespeichert!');
    };

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-xl font-bold mb-4">API Key verwalten</h1>
            <input
                type="text"
                value={apiKey}
                onChange={(e) => setApiKey(e.target.value)}
                className="border p-2 w-full mb-4"
                placeholder="API Key eingeben"
            />
            <button
                onClick={handleSave}
                className="bg-blue-500 text-white py-2 px-4 rounded"
            >
                Speichern
            </button>
        </div>
    );
};

export default TokensPage;
