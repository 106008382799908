
class Strapi {
    static MAIN_ADDRESS = "https://datahub.diestadt.app"

    static API_TOKEN = "d5782a871f74859a07920b24ae9ca7c4d32956697f4c7ce47307f91df0d3c0d05e04542bcb4004742758a590141eefdc96168199843d904a368b40352774cfa99bac1a44a9e9891e2b2c7110301e42d4170cb2c9516f580256ee532e8561c98f4902be5f3985eda7559bcca925e35ca77ebbc2f0fafabc99588e4f10bb2eed5f"
    static API_ADDRESS = Strapi.MAIN_ADDRESS + "/api"
    static DEFAULT_HEADERS = {
        'Authorization': `Bearer ${Strapi.API_TOKEN}`,
        "Content-Type": 'application/json'
    }

}

module.exports = {
    Strapi
};