import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './styles/colors.scss';
import './styles/index.scss';

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (username.toLowerCase() === 'sylphen' && password === 'iuDU7xnd83nd9jfihd') {
      onLogin();
    } else {
      alert('Falscher Benutzername oder Passwort');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>Benutzername:</label>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </div>
      <div>
        <label>Passwort:</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <button type="submit">Login</button>
    </form>
  );
};


const RootComponent = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const isLoggedIn = localStorage.getItem('isLoggedIn');
    const expiryDate = localStorage.getItem('expiryDate');

    if (isLoggedIn && new Date() < new Date(expiryDate)) {
      setIsAuthenticated(true);
    }
  }, []);

  const handleLogin = () => {
    const expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + 30);
    localStorage.setItem('isLoggedIn', 'true');
    localStorage.setItem('expiryDate', expiryDate.toString());
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('expiryDate');
    setIsAuthenticated(false);
  };

  return (
    isAuthenticated ? <App onLogout={handleLogout} /> : <Login onLogin={handleLogin} />
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RootComponent />
  </React.StrictMode>
);

reportWebVitals();
