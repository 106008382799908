import React, { useState } from "react"
import "./ErrorBox.scss"

function ErrorBox({ title, desc, onPress = () => { } }) {

    const [IsOpen, setIsOpen] = useState(true)
    if (!title) return <></>

    function toggle(e) {
        // if (IsOpen) { onPress && onPress() }
        // e.preventDefault()
        // setIsOpen(!IsOpen)
    }

    return (
        <div className="ErrorBox" onClick={toggle}>
            <span className="title">{title}</span>
            {IsOpen && <span className="desc">{desc}</span>}
        </div>
    )
}
export default ErrorBox