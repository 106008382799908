// App.js
// App.js
import React, { useEffect, useState } from 'react';

import axios from 'axios';
import EmailListErrors from '../../container/EmailListErrors';
import { Strapi } from '../../functions/Strapi';


const ArticleErrorPage = () => {

    const [Data, setData] = useState(null);
    const [Loading, setLoading] = useState(true);

    const fetchData = async () => {
        try {
            const response = await axios({
                url: Strapi.API_ADDRESS + "/current-article?populate[0]=mail&populate[1]=mail.sender",
                headers: Strapi.DEFAULT_HEADERS,
                method: "GET"
            });
            setData(response.data?.data?.attributes);
            setLoading(false);
        } catch (error) {
            console.error('Fehler beim Abrufen der Daten:', error);
        }
    };

    useEffect(() => {
        fetchData();
        const interval = setInterval(() => {
            fetchData();
        }, 5 * 1000);

        return () => clearInterval(interval);
    }, []);

    if (Loading || !Data) return <p>Lädt...</p>;

    return (
        <div className="container mx-auto p-4">


            <div className='row'>
                <div className='col-12 col-lg-12'>
                    <EmailListErrors title="Fehler" currentMailID={Data?.mail?.data?.id} />
                </div>
            </div>
        </div>
    );
};

export default ArticleErrorPage;
