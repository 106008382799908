import { useEffect, useState } from "react";
import { formatTimeAgo } from "../functions/formatTimeAgo";

export const TimeAgo = ({ children }) => {
    const [timeAgo, setTimeAgo] = useState(formatTimeAgo(children));

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeAgo(formatTimeAgo(children));
        }, 1000);

        return () => clearInterval(interval);
    }, [children]);

    return <span className="TimeAgo date">{timeAgo}</span>;
};
