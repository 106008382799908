// EmailListErrors.js
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import ErrorBox from '../components/ErrorBox';
import { TimeAgo } from '../components/TimeAgo';
import { Strapi } from '../functions/Strapi';
import "./EmailListErrors.scss";

const EmailListErrors = ({ currentMailID }) => {
    const [Article, setArticle] = useState([]);
    const [Loading, setLoading] = useState(true);
    const [Count, setCount] = useState(0);
    const [FilterTitle, setFilterTitle] = useState("Anhänge");

    const fetchData = async () => {
        let bonusFilter = "";

        if (FilterTitle === "Anhänge") {
            bonusFilter = "&filters[progressErrorTitle][$eqi]=Anh\u00E4nge";
        }

        try {
            const response = await axios({
                url: Strapi.API_ADDRESS + "/mails?sort=headerDate:DESC&filters[type][$eq]=PRESS_RELEASE&filters[article][id][$null]=true&filters[progressErrorTitle][$null]=false&pagination[pageSize]=100",
                headers: Strapi.DEFAULT_HEADERS,
                method: "GET"
            });
            setArticle(response.data?.data);
            setCount(response?.data?.meta?.pagination?.total);
            setLoading(false);
        } catch (error) {
            console.error('Fehler beim Abrufen der Daten:', error);
        }
    };

    useEffect(() => {
        fetchData();
        const interval = setInterval(() => {
            fetchData();
        }, 10000);

        return () => clearInterval(interval);
    }, []);

    const handleRemoveAllErrors = async () => {
        if (window.confirm("Möchten Sie alle Fehler entfernen?")) {
            try {
                const errorIds = Article.map(item => item.id);
                await Promise.all(errorIds.map(id => removeErrorFromMail(id)));
                console.log('Alle Fehler entfernt');
                fetchData(); // Daten neu laden, um aktualisierte Liste zu zeigen
            } catch (error) {
                console.error("Fehler beim Entfernen aller Fehler:", error);
                alert("Fehler beim Entfernen aller Fehler.");
            }
        }
    };

    // Funktion zum Entfernen des Fehlers
    const removeErrorFromMail = (mailId) => {
        const apiToken = localStorage.getItem('strapiApiKey'); // Token aus dem Local Storage holen

        if (!apiToken) {
            throw new Error('API Token nicht gefunden');
        }

        return axios({
            url: `https://datahub.diestadt.app/api/mails/${mailId}`,
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${apiToken}`,
            },
            data: JSON.stringify({
                data: {
                    progressError: null,
                    progressErrorTitle: null,
                }
            })

        })
            .then(response => {
                return;
            })
            .catch(error => {
                console.error('Fehler beim Entfernen des Fehlers:', error);
                throw new Error('Fehler beim Entfernen des Fehlers: ' + error.message);
            });
    };

    if (Loading) return <p>Lädt...</p>;

    let timePerArticle = 26;
    let pricePerArticle = 0.04;
    let time = Math.round(Count * timePerArticle / 60);
    let costs = Math.round(pricePerArticle * Count);

    return (
        <div className='p-3 EmailListErrors'>
            <h2 className="text-lg font-semibold mb-2">Fehler ({Count})</h2>
            <span>Dauer: ca. {Math.round(time / 60)} min. | Kosten ca. {costs}$</span>
            <br />
            <button onClick={handleRemoveAllErrors} className='remove-all-button'>Alle Fehler löschen</button>
            {Article.map((i, k) => <EmailItem {...i} key={k} currentMailID={currentMailID} />)}
        </div>
    );
};
export default EmailListErrors;

function EmailItem({ id, attributes, currentMailID }) {
    const [Deleted, setDeleted] = useState(false);
    const { subject, headerDate, progressError, progressErrorTitle } = attributes;
    const strapiUrl = `https://datahub.diestadt.app/admin/content-manager/collection-types/api::mail.mail/${id}`;

    const isActive = currentMailID === id;

    const handlePress = async () => {
        if (true) {
            // if (window.confirm("Fehler entfernen?")) {
            try {
                await removeErrorFromMail(id);
                console.log('Fehler entfernt: Mail-ID_' + id);
                setDeleted(true);
            } catch (error) {
                console.error("Fehler beim Entfernen des Fehlers:", error);
                alert("Fehler beim Entfernen des Fehlers.");
            }
        }
    };

    // Funktion zum Entfernen des Fehlers
    const removeErrorFromMail = (mailId) => {
        const apiToken = localStorage.getItem('strapiApiKey'); // Token aus dem Local Storage holen

        if (!apiToken) {
            throw new Error('API Token nicht gefunden');
        }

        return axios({
            url: `https://datahub.diestadt.app/api/mails/${mailId}`,
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${apiToken}`,
            },
            data: JSON.stringify({
                data: {
                    progressError: null,
                    progressErrorTitle: null,
                }
            })

        })
            .then(response => {
                return;
            })
            .catch(error => {
                console.error('Fehler beim Entfernen des Fehlers:', error);
                throw new Error('Fehler beim Entfernen des Fehlers: ' + error.message);
            });
    };

    return (
        <div className={`item ${isActive ? 'isActive' : ''}`} style={{ opacity: Deleted ? 0.2 : 1 }}>
            <a href={strapiUrl} target='_blank' rel='noopener noreferrer'>
                <span className='title' title={subject}>{subject}</span>
            </a>
            {isActive && <span>Wird bearbeitet...</span>}
            <TimeAgo>{headerDate}</TimeAgo>
            <ErrorBox title={progressErrorTitle} desc={progressError} />
            <button onClick={handlePress}>Fehler entfernen</button>
        </div>
    );
}
