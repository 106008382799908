// EmailList.js
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { TimeAgo } from '../../components/TimeAgo';
import { Strapi } from '../../functions/Strapi';
import { formatTimeAgo } from '../../functions/formatTimeAgo';
import "./NewEventsList.scss";

const NewEventsList = ({ }) => {

    const [Article, setArticle] = useState([])
    const [Loading, setLoading] = useState(true)
    const [Total, setTotal] = useState(0)

    const fetchData = async () => {
        try {
            let url = Strapi.API_ADDRESS + "/events?sort=id:DESC&endTimestamp=2030-08-01&populate[0]=titleImage"
            console.log("url_:" + url)
            const response = await axios({
                url: url,
                headers: Strapi.DEFAULT_HEADERS,
                method: "GET"
            });
            setArticle(response.data?.data);
            setLoading(false)
            setTotal(response.data?.meta?.pagination?.total)
        } catch (error) {
            console.error('Fehler beim Abrufen der Daten:', error);
        }
    };

    useEffect(() => {
        fetchData();
        const interval = setInterval(() => {
            fetchData();
        }, 10000);

        return () => clearInterval(interval);
    }, []);


    if (Loading) return <p>Lädt...</p>
    return (
        <div className='NewEventsList FinishList p-3'>
            <h2 className="text-lg font-semibold mb-2">Fertige Events ({Total})</h2>
            {Article.map((i, k) => <EventItem {...i} key={k} />)}
        </div>
    );
};

export default NewEventsList;

function EventItem({ id, attributes }) {
    let { title, slug, createdAt, author = {}, titleImage, location, description, srcJSON } = attributes

    const [timeAgo, setTimeAgo] = useState(formatTimeAgo(createdAt));

    if (!slug) return <p>Kein SLUG!!! IID: {id}</p>
    let url = "https://herold.news.app/event/" + slug.toLowerCase()

    console.log(titleImage)

    let imgSrc = Strapi.MAIN_ADDRESS + titleImage?.data?.attributes?.url

    let strapiUrl = Strapi.MAIN_ADDRESS + "/admin/content-manager/collection-types/api::event.event/" + id



    let warnings = []

    if (!description) warnings.push("Keine Beschreibung")

    return (
        <a href={url} target='_blank' className='EventItem'>
            <div className='col-3'>
                <a href={imgSrc} target='_blank'>
                    <img src={imgSrc} className='w-100' />
                </a>
            </div>

            <div className='p-2'>
                <span className='title'>{title}</span>
                <span className='desc'>{description}</span>

                <a className='strapi' href={strapiUrl} target='_blank'>Strapi</a>

                <div className='warnings'>
                    {warnings.map((i, k) => <span className='warning'>{i}</span>)}
                </div>


                <div>
                    {/* <span>{JSON.stringify(location || "Ka")}</span> */}
                    <TimeAgo title="Gecrawlt vor">{createdAt}</TimeAgo>
                </div>

            </div>


            <div>
                <code>
                    {JSON.stringify(srcJSON).replace(",", ",\n") || "NoSRC?"}
                </code>
            </div>

        </a>)
}

