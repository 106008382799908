export function updateBaustellen(baustellenArticleData) {
    console.log("Updating Baustellen with Sylphen source");
    return baustellenArticleData.map(baustelle => {
        console.log(baustelle.attributes);

        let slug = baustelle.attributes.slug;
        let authorSlug = baustelle.attributes.author.data.attributes.slug;
        let url = "https://herold.news/" + (authorSlug || 'a') + "/" + slug;

        return {
            ...baustelle,
            attributes: {
                ...baustelle.attributes,
                detourJson: {
                    ...baustelle.attributes.detourJson,
                    source: url,
                },
            },
        };
    });
}
